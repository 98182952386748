@font-face {
  font-family: "Avenir";
  src: url(./fonts/Avenir/Avenir-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Avenir";
  src: url(./fonts/Avenir/Avenir-Heavy.ttf);
  font-weight: 700;
}
@font-face {
  font-family: "Avenir";
  src: url(./fonts/Avenir/Avenir-Book.ttf);
  font-weight: 400;
}
@font-face {
  font-family: "Bebas";
  src: url(./fonts/Bebas/Bebas-Regular.ttf);
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0;
}

body {
  /* text-align: center; */
  font-family: 'Avenir', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
html {
  overflow-x: hidden;
}

code {
  font-family: Menlo, Monaco, Consolas, 'Courier New', monospace;
}
