.Button {
    font-weight: 900;
    padding: 3px 0;
    cursor: pointer;
    border-width: 3px;
    border-style: solid;
    border-radius: 5px;
    transition: .5s;
    display: inline-block;
    a {
        padding: 6px 27px;
        color: white;
        text-decoration: none;
    }
}

.Button.green {
    background: rgb(90, 192, 115);
    border: 3px solid rgb(84, 177, 107);
}
.Button.green:hover {
    background: rgb(84, 177, 107);
}

.Button.blue {
    background: rgb(62, 121, 197);
    border: 3px solid rgb(50, 105, 177);
}
.Button.blue:hover {
    background: rgb(50, 105, 177);
}

.Button.live {
    padding: 2px 0;
    background: rgb(192, 90, 90);
    border: 3px solid rgb(177, 84, 84);
    transform: scale(2);
    a {
        padding: 4px 16px;
    }
}
.Button.live:hover {
    background: rgb(177, 84, 84);
}