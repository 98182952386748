.Home {
    .homeWrapper {
        $padding: 11.5vw;
        margin: auto;
        width: calc(100vw - (#{$padding} * 2));
        padding: 0 $padding;
        max-width: 550px;
    }
}

@media (max-width: 450px) {
    .Home {
        .homeWrapper {
            h1 {
                font-size: 1.7em;
            }
        }
    }
}